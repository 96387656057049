import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <div className="App-body">
        <p>
          Nueva Urbana
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default App;
