import React from 'react';
import '../App.css';

const Footer = () => (
  <footer className='App-footer'>
    <p>&copy; { new Date().getFullYear()} - Nueva Urbana Spa</p>
  </footer>
);

Footer.displayName = "Footer";

export default Footer;