import React from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import '../App.css';

const Header = () => (
  <AppBar position="sticky" className='App-header'>
    <Toolbar>
      <Button color="inherit">Login</Button>
    </Toolbar>
  </AppBar>
);

Header.displayName = "Header";

export default Header;
